import { makeStyles } from 'tss-react/mui';

import { COLORS } from '@@frontend/styles';

interface Props {
    borderRadius?: number;
    backgroundColor?: string;
    padding?: number;
    width?: number | string;
}

export default makeStyles<Props>({ name: 'paper-container' })((theme, props) => ({
  root: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: props.borderRadius || 50,
    borderBottomRightRadius: props.borderRadius || 50,
    backgroundColor: props.backgroundColor || COLORS.WHITE,
    padding: props.padding || theme.spacing(4),
    width: props.width || 'initial',
  },
}));
