import { NOTIFICATIONS_ACTIONS } from '@@frontend/constants/actions';

import type { NotificationMenuEntry } from '@@common-api/api/notification';
import type { Reducer } from 'redux';

export type NotificationsData = readonly NotificationMenuEntry[];

export type NotificationsStateType = {
  entries: NotificationsData | null;
}

const initialState: NotificationsStateType = {
  entries: null,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const notificationsReducer: Reducer<NotificationsStateType> = (state: NotificationsStateType = initialState, action): NotificationsStateType => {
  switch (action.type) {
    case NOTIFICATIONS_ACTIONS.SET_NOTIFICATIONS_DATA: {
      return {
        ...state,
        entries: [...action.data],
      };
    }

    default:
      return state;
  }
};

export default notificationsReducer;
