import { useRouteError } from 'react-router-dom';

import { UncaughtUIError } from '@@frontend/components/AppRouter/components/UncaughtUIError/UncaughtUIError';
import { handleError } from '@@frontend/services/sentry';

import type { ReactElement } from 'react';

const ErrorBoundary = (): ReactElement => {
  const error = useRouteError();

  if (error) {
    handleError(error);
  }

  return <UncaughtUIError error={ error } />;
};

export default ErrorBoundary;
