import { Outlet, ScrollRestoration } from 'react-router-dom';

import type { FC } from 'react';

export const AppRoot: FC = () => {
  return <div
    className="min-h-screen w-full flex"
    data-testid="app-root"
  >
    <Outlet />
    <ScrollRestoration />
  </div>;
};
