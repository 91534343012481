/* eslint-disable @typescript-eslint/ban-types,@typescript-eslint/no-explicit-any,@typescript-eslint/no-unused-vars */

import { NoParamsRoute, ParamsRoute } from './types';

import type { BaseUrlType, ResolveUrlOptions } from './types';
import type { Except } from 'type-fest';

export type RoutesDef = NoParamsRouteDef<any> | ParamsRouteDef<any, any>;
export type RoutesDefNode = RoutesDef | Except<RoutesDefTree, 'path'>;
export type RoutesDefTree = {
  [P in string]: RoutesDefNode;
};

export type Routes<Def extends RoutesDefTree, B extends BaseUrlType> = {
  [P in keyof Def]: Def[P] extends ParamsRouteDef<infer T, infer Q> ? ParamsRoute<T, Q, B>
    : Def[P] extends NoParamsRouteDef<infer Q> ? NoParamsRoute<Q, B>
    : Def[P] extends string ? string
    : Def[P] extends RoutesDefTree ? Routes<Def[P], B>
    : void;
}

export function isRouteDef(
  routeDef: Exclude<RoutesDefNode, string>,
): routeDef is NoParamsRouteDef<unknown> | ParamsRouteDef<unknown & Params, unknown> {
  return !!routeDef.path;
}

export function createRoutesFromDefinitions<T extends RoutesDefTree, B extends BaseUrlType>(
  definitions: T,
  options: ResolveUrlOptions<B>,
): Routes<T, B> {
  return Object.fromEntries(Object.entries(definitions).map(([key, routeDef]) => {
    let value;
    if (isRouteDef(routeDef)) {
      value = routeDef.hasParams
        ? new ParamsRoute(routeDef.path, options)
        : new NoParamsRoute(routeDef.path, options);
    } else {
      value = createRoutesFromDefinitions(routeDef as RoutesDefTree, options);
    }
    return [key, value];
  })) as Routes<T, B>;
}

type NoParamsRouteDef<Q> = {
  readonly hasParams: false,
  readonly path: string,
  ['#_this_property_does_not_exist_it_is_just_a_marker_for_the_query_type']: Q,
}
type Params = Record<string, string | undefined>;
type ParamsRouteDef<T extends Params, Q> = {
  readonly hasParams: true,
  readonly path: string,
  ['#_this_property_does_not_exist_it_is_just_a_marker_for_the_params_type']: T,
  ['#_this_property_does_not_exist_it_is_just_a_marker_for_the_query_type']: Q,
}

export function route<Q = never>(path: string): NoParamsRouteDef<Q> {
  return { hasParams: false, path } as NoParamsRouteDef<Q>;
}
export function routeP<T extends Params, Q = never>(path: string): ParamsRouteDef<T, Q> {
  return { hasParams: true, path } as ParamsRouteDef<T, Q>;
}

export type RouteParams<R> = R extends ParamsRoute<infer P, any, any> ? P : never;
export type RouteQuery<R> = R extends ParamsRoute<any, infer Q, any> ? Q : R extends NoParamsRoute<infer Q, any> ? Q : never;
