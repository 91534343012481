import type { ApiFailure } from '@@common-api/apiResult';
import type { AxiosResponse } from 'axios';

// eslint-disable-next-line no-var
declare var Error: ErrorConstructor;

// add options parameter to Error constructor
interface ErrorConstructor {
  new(message?: string, options?: { cause: any }): Error;
  (message?: string, options?: { cause: any }): Error;
  readonly prototype: Error;
}

function isApiFailure(result: any): result is ApiFailure {
  return (result?.type === 'failure' && typeof result.errorCode === 'string');
}

export class FunctionCallError extends Error {
  constructor(message: string, readonly response: AxiosResponse, readonly failure?: ApiFailure, options?: { cause: any }) {
    super(message, options);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static tryGetFailure(error: any): ApiFailure | undefined {
    if (!(error instanceof Error)) {
      return undefined;
    }

    const maybeFunctionCallError = error as FunctionCallError;
    if (isApiFailure(maybeFunctionCallError.failure)) {
      return maybeFunctionCallError.failure;
    }

    return undefined;
  }
}
