import * as Sentry from '@sentry/react';
import { escapeRegExp } from 'lodash';
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

import CONFIG from '@@frontend/config';
import { FunctionCallError } from '@@frontend/services/APIClient/FunctionCallError';

import type { CaptureContext, ScopeContext } from '@sentry/types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const handleError = (error: any, captureContext?: CaptureContext): void => {
  let context = captureContext;
  const apiFailure = FunctionCallError.tryGetFailure(error);
  if (apiFailure) {
    context = {
      ...context,
      contexts: {
        ...((context as ScopeContext)?.contexts),
        apiFailure: {
          errorCode: apiFailure.errorCode,
          consoleMessage: apiFailure.consoleMessage,
        },
      },
    };
  }

  Sentry.captureException(error, context);

  // Ignore "no changes" responses from FunctionListener
  if (error.message === 'Request failed with status code 304') return;
  console.error(error);
};

export const initSentry = (): void => {
  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      dsn: 'https://d5132a1ccce14c948116dcf363c492d1@o499740.ingest.sentry.io/5958596',
      autoSessionTracking: true,
      environment: process.env.REACT_APP_ENVIRONMENT_NAME,
      tracePropagationTargets: [`^${escapeRegExp(CONFIG.API_URL)}`],
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        Sentry.httpClientIntegration(),
        Sentry.feedbackAsyncIntegration({
          autoInject: false,
          showBranding: false,
          colorScheme: 'system',
          showName: false,
          showEmail: false,
        }),
      ],
      tracesSampleRate: 1.0,
      ignoreErrors: [
        // Ignore "no changes" responses from FunctionListener
        'Error: Request failed with status code 304',
        // Some of the resize observations could not be delivered on time, can be rather safely ignored
        // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
        'Error: ResizeObserver loop limit exceeded',
      ],
      release: CONFIG.getAppVersion(),
    });
  }
};
