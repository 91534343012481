import { HEALTHCARE_INSTITUTION_DEPARTMENT_ACTIONS } from '@@frontend/constants/actions';

import type { Department } from '@@common-legacy/healthcareInstitutionsDepartments/types';
import type { Reducer } from 'redux';

type HealthcareInstitutionDepartmentStateType = {
  preselectedDepartmentData: Partial<Department>;
}

const initialState: HealthcareInstitutionDepartmentStateType = {
  preselectedDepartmentData: {},
};

const healthcareInstitutionDepartmentReducer: Reducer<HealthcareInstitutionDepartmentStateType> = (
// eslint-disable-next-line @typescript-eslint/default-param-last
  state: HealthcareInstitutionDepartmentStateType = initialState,
  action,
): HealthcareInstitutionDepartmentStateType => {
  switch (action.type) {
    case HEALTHCARE_INSTITUTION_DEPARTMENT_ACTIONS.SET_PRESELECTED_HEALTHCARE_INSTITUTION_DEPARTMENT_DATA: {
      return {
        ...state,
        preselectedDepartmentData: action.data,
      };
    }
    case HEALTHCARE_INSTITUTION_DEPARTMENT_ACTIONS.CLEAR_PRESELECTED_HEALTHCARE_INSTITUTION_DEPARTMENT_DATA: {
      return {
        ...state,
        preselectedDepartmentData: {},
      };
    }
    default:
      return state;
  }
};

export default healthcareInstitutionDepartmentReducer;
