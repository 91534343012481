import { formatDateRange } from '@@util/date/DateRange';

import type { DateRangeFormatOptions } from '@@util/date/DateRange';
import type { LocalDate } from '@@util/date/LocalDate';

export type LocalDateRangeLike = { startDate: LocalDate, endDate: LocalDate };

export type LocalDateRangeFormatOptions = DateRangeFormatOptions;

export class LocalDateRange {
  private constructor(
    readonly startDate: LocalDate,
    readonly endDate: LocalDate,
  ) {
    if (startDate > endDate) {
      throw new Error(`startDate (${startDate}) must be <= endDate (${endDate})`);
    }
  }

  static between(startDate: LocalDate, endDate: LocalDate): LocalDateRange {
    return new LocalDateRange(startDate, endDate);
  }

  static fromRange(range: LocalDateRangeLike): LocalDateRange {
    return new LocalDateRange(range.startDate, range.endDate);
  }

  format(options?: DateRangeFormatOptions): string {
    return formatDateRange(this, options);
  }

  private* iterateDays(): Generator<LocalDate> {
    const { startDate, endDate } = this;
    let date = startDate;
    while (date <= endDate) {
      yield date;
      date = date.plus({ day: 1 });
    }
  }

  [Symbol.iterator](): IterableIterator<LocalDate> {
    return this.iterateDays();
  }

  toArray(): LocalDate[] {
    return Array.from(this);
  }

  map<T>(mapFn: (value: LocalDate, index: number) => T): T[] {
    return Array.from(this, mapFn);
  }

  length(): number {
    return this.endDate.diff(this.startDate, 'days').days + 1;
  }

  isAfter(otherRange: LocalDateRange): boolean {
    return this.startDate > otherRange.endDate;
  }

  isBefore(otherRange: LocalDateRange): boolean {
    return this.endDate < otherRange.startDate;
  }

  equals(otherRange: LocalDateRange): boolean {
    return this.startDate.equals(otherRange.startDate) && this.endDate.equals(otherRange.endDate);
  }

  overlaps(otherRange: LocalDateRange): boolean {
    return !this.isAfter(otherRange) && !this.isBefore(otherRange);
  }

  contains(otherRange: LocalDateRangeLike): boolean;
  contains(otherRange: LocalDateRange): boolean {
    return this.startDate <= otherRange.startDate && this.endDate >= otherRange.endDate;
  }

  includes(date: LocalDate): boolean {
    return this.startDate <= date && this.endDate >= date;
  }
}
