/* eslint-disable @typescript-eslint/no-empty-interface,max-len */
import { DateTime as LuxonDateTime } from 'luxon';

import { InvalidDateTimeError } from '@@util/date/errors';

import type {
  DateObjectUnits,
  DateTimeFormatOptions,
  DateTimeOptions,
  DayNumbers,
  HourNumbers, LocaleOptions, DateTimeOptions as LuxonDateTimeOptions, MinuteNumbers,
  MonthNumbers,
  PossibleDaysInMonth, PossibleDaysInYear, PossibleWeeksInYear,
  QuarterNumbers,

  SecondNumbers,
  ToISODateOptions,
  ToISOTimeOptions,
  ToSQLOptions,

  WeekNumbers,
  WeekdayNumbers,
} from 'luxon';
import type { DateTimeJSOptions, DateTimeUnit } from 'luxon/src/datetime';
import type { DurationLike } from 'luxon/src/duration';
import type { Zone } from 'luxon/src/zone';

export { Interval, Duration } from 'luxon';
export type { DateInput, DurationLike, DurationObjectUnits } from 'luxon';

type DateTimeWrapperOptions = {
  throwOnInvalid?: boolean;
}

type OptDateTime<Options extends DateTimeWrapperOptions> = Options extends { throwOnInvalid: true } ? DateTime : DateTimeOrInvalid;

export type DateTimeOrInvalid = {
  [K in keyof LuxonDateTime]: LuxonDateTime[K] extends (...args: infer A) => LuxonDateTime ? (...args: A) => DateTimeOrInvalid : LuxonDateTime[K];
}

export interface DateTime extends DateTimeOrInvalid {
  get locale(): string;

  /**
   * Get the numbering system of a DateTime, such as 'beng'. The numbering system is used when formatting the DateTime
   */
  get numberingSystem(): string;

  /**
   * Get the output calendar of a DateTime, such as 'islamic'. The output calendar is used when formatting the DateTime
   */
  get outputCalendar(): string;

  /**
   * Get the time zone associated with this DateTime.
   */
  get zone(): Zone;

  /**
   * Get the name of the time zone.
   */
  get zoneName(): string;

  /**
   * Get the year
   *
   * @example DateTime.local(2017, 5, 25).year //=> 2017
   */
  get year(): number;

  /**
   * Get the quarter
   *
   * @example DateTime.local(2017, 5, 25).quarter //=> 2
   */
  get quarter(): QuarterNumbers;

  /**
   * Get the month (1-12).
   *
   * @example DateTime.local(2017, 5, 25).month //=> 5
   */
  get month(): MonthNumbers;

  /**
   * Get the day of the month (1-30ish).
   *
   * @example DateTime.local(2017, 5, 25).day //=> 25
   */
  get day(): DayNumbers;

  /**
   * Get the hour of the day (0-23).
   *
   * @example DateTime.local(2017, 5, 25, 9).hour //=> 9
   */
  get hour(): HourNumbers;

  /**
   * Get the minute of the hour (0-59).
   *
   * @example
   * DateTime.local(2017, 5, 25, 9, 30).minute //=> 30
   */
  get minute(): MinuteNumbers;

  /**
   * Get the second of the minute (0-59).
   *
   * @example
   * DateTime.local(2017, 5, 25, 9, 30, 52).second //=> 52
   */
  get second(): SecondNumbers;

  /**
   * Get the millisecond of the second (0-999).
   *
   * @example
   * DateTime.local(2017, 5, 25, 9, 30, 52, 654).millisecond //=> 654
   */
  get millisecond(): number;

  /**
   * Get the week year
   * @see https://en.wikipedia.org/wiki/ISO_week_date
   *
   * @example
   * DateTime.local(2014, 12, 31).weekYear //=> 2015
   */
  get weekYear(): number;

  /**
   * Get the week number of the week year (1-52ish).
   * @see https://en.wikipedia.org/wiki/ISO_week_date
   *
   * @example
   * DateTime.local(2017, 5, 25).weekNumber //=> 21
   */
  get weekNumber(): WeekNumbers;

  /**
   * Get the day of the week.
   * 1 is Monday and 7 is Sunday
   * @see https://en.wikipedia.org/wiki/ISO_week_date
   *
   * @example
   * DateTime.local(2014, 11, 31).weekday //=> 4
   */
  get weekday(): WeekdayNumbers;

  /**
   * Get the ordinal (meaning the day of the year)
   *
   * @example
   * DateTime.local(2017, 5, 25).ordinal //=> 145
   */
  get ordinal(): number;

  /**
   * Get the human readable short month name, such as 'Oct'.
   * Defaults to the system's locale if no locale has been specified
   *
   * @example
   * DateTime.local(2017, 10, 30).monthShort //=> Oct
   */
  get monthShort(): string;

  /**
   * Get the human readable long month name, such as 'October'.
   * Defaults to the system's locale if no locale has been specified
   *
   * @example
   * DateTime.local(2017, 10, 30).monthLong //=> October
   */
  get monthLong(): string;

  /**
   * Get the human readable short weekday, such as 'Mon'.
   * Defaults to the system's locale if no locale has been specified
   *
   * @example
   * DateTime.local(2017, 10, 30).weekdayShort //=> Mon
   */
  get weekdayShort(): string;

  /**
   * Get the human readable long weekday, such as 'Monday'.
   * Defaults to the system's locale if no locale has been specified
   *
   * @example
   * DateTime.local(2017, 10, 30).weekdayLong //=> Monday
   */
  get weekdayLong(): string;

  /**
   * Get the UTC offset of this DateTime in minutes
   *
   * @example
   * DateTime.now().offset //=> -240
   * @example
   * DateTime.utc().offset //=> 0
   */
  get offset(): number;

  /**
   * Get the short human name for the zone's current offset, for example "EST" or "EDT".
   * Defaults to the system's locale if no locale has been specified
   */
  get offsetNameShort(): string;

  /**
   * Get the long human name for the zone's current offset, for example "Eastern Standard Time" or "Eastern Daylight Time".
   * Defaults to the system's locale if no locale has been specified
   */
  get offsetNameLong(): string;

  /**
   * Get whether this zone's offset ever changes, as in a DST.
   */
  get isOffsetFixed(): boolean;

  /**
   * Get whether the DateTime is in a DST.
   */
  get isInDST(): boolean;

  /**
   * Returns true if this DateTime is in a leap year, false otherwise
   *
   * @example
   * DateTime.local(2016).isInLeapYear //=> true
   * @example
   * DateTime.local(2013).isInLeapYear //=> false
   */
  get isInLeapYear(): boolean;

  /**
   * Returns the number of days in this DateTime's month
   *
   * @example
   * DateTime.local(2016, 2).daysInMonth //=> 29
   * @example
   * DateTime.local(2016, 3).daysInMonth //=> 31
   */
  get daysInMonth(): PossibleDaysInMonth;

  /**
   * Returns the number of days in this DateTime's year
   *
   * @example
   * DateTime.local(2016).daysInYear //=> 366
   * @example
   * DateTime.local(2013).daysInYear //=> 365
   */
  get daysInYear(): PossibleDaysInYear;

  /**
   * Returns the number of weeks in this DateTime's year
   * @see https://en.wikipedia.org/wiki/ISO_week_date
   *
   * @example
   * DateTime.local(2004).weeksInWeekYear //=> 53
   * @example
   * DateTime.local(2013).weeksInWeekYear //=> 52
   */
  get weeksInWeekYear(): PossibleWeeksInYear;

  /**
   * Adding hours, minutes, seconds, or milliseconds increases the timestamp by the right number of milliseconds. Adding days, months, or years shifts the calendar,
   * accounting for DSTs and leap years along the way. Thus, `dt.plus({ hours: 24 })` may result in a different time than `dt.plus({ days: 1 })` if there's a DST shift in between.
   *
   * @param duration - The amount to add. Either a Luxon Duration, a number of milliseconds, the object argument to Duration.fromObject()
   *
   * @example
   * DateTime.now().plus(123) //~> in 123 milliseconds
   * @example
   * DateTime.now().plus({ minutes: 15 }) //~> in 15 minutes
   * @example
   * DateTime.now().plus({ days: 1 }) //~> this time tomorrow
   * @example
   * DateTime.now().plus({ days: -1 }) //~> this time yesterday
   * @example
   * DateTime.now().plus({ hours: 3, minutes: 13 }) //~> in 3 hr, 13 min
   * @example
   * DateTime.now().plus(Duration.fromObject({ hours: 3, minutes: 13 })) //~> in 3 hr, 13 min
   */
  plus(duration: DurationLike): DateTime;

  /**
   * See {@link DateTime.plus}
   *
   * @param duration - The amount to subtract. Either a Luxon Duration, a number of milliseconds, the object argument to Duration.fromObject()
   */
  minus(duration: DurationLike): DateTime;

  /**
   * "Set" this DateTime to the beginning of the given unit.
   *
   * @param unit - The unit to go to the beginning of. Can be 'year', 'quarter', 'month', 'week', 'day', 'hour', 'minute', 'second', or 'millisecond'.
   *
   * @example
   * DateTime.local(2014, 3, 3).startOf('month').toISODate(); //=> '2014-03-01'
   * @example
   * DateTime.local(2014, 3, 3).startOf('year').toISODate(); //=> '2014-01-01'
   * @example
   * DateTime.local(2014, 3, 3).startOf('week').toISODate(); //=> '2014-03-03', weeks always start on Mondays
   * @example
   * DateTime.local(2014, 3, 3, 5, 30).startOf('day').toISOTime(); //=> '00:00.000-05:00'
   * @example
   * DateTime.local(2014, 3, 3, 5, 30).startOf('hour').toISOTime(); //=> '05:00:00.000-05:00'
   */
  startOf(unit: DateTimeUnit): DateTime;

  /**
   * "Set" this DateTime to the end (meaning the last millisecond) of a unit of time
   *
   * @param unit - The unit to go to the end of. Can be 'year', 'quarter', 'month', 'week', 'day', 'hour', 'minute', 'second', or 'millisecond'.
   *
   * @example
   * DateTime.local(2014, 3, 3).endOf('month').toISO(); //=> '2014-03-31T23:59:59.999-05:00'
   * @example
   * DateTime.local(2014, 3, 3).endOf('year').toISO(); //=> '2014-12-31T23:59:59.999-05:00'
   * @example
   * DateTime.local(2014, 3, 3).endOf('week').toISO(); // => '2014-03-09T23:59:59.999-05:00', weeks start on Mondays
   * @example
   * DateTime.local(2014, 3, 3, 5, 30).endOf('day').toISO(); //=> '2014-03-03T23:59:59.999-05:00'
   * @example
   * DateTime.local(2014, 3, 3, 5, 30).endOf('hour').toISO(); //=> '2014-03-03T05:59:59.999-05:00'
   */
  endOf(unit: DateTimeUnit): DateTime;

  /**
   * Returns a string representation of this DateTime formatted according to the specified format string.
   * **You may not want this.** See {@link DateTime.toLocaleString} for a more flexible formatting tool. For a table of tokens and their interpretations,
   * see [here](https://moment.github.io/luxon/#/formatting?id=table-of-tokens).
   * Defaults to en-US if no locale has been specified, regardless of the system's locale.
   *
   * @param fmt - the format string
   * @param opts - opts to override the configuration options on this DateTime
   *
   * @example
   * DateTime.now().toFormat('yyyy LLL dd') //=> '2017 Apr 22'
   * @example
   * DateTime.now().setLocale('fr').toFormat('yyyy LLL dd') //=> '2017 avr. 22'
   * @example
   * DateTime.now().toFormat('yyyy LLL dd', { locale: "fr" }) //=> '2017 avr. 22'
   * @example
   * DateTime.now().toFormat("HH 'hours and' mm 'minutes'") //=> '20 hours and 55 minutes'
   */
  toFormat(fmt: string, opts?: LocaleOptions): string;

  /**
   * Returns a localized string representing this date. Accepts the same options as the Intl.DateTimeFormat constructor and any presets defined by Luxon,
   * such as `DateTime.DATE_FULL` or `DateTime.TIME_SIMPLE` of the DateTime in the assigned locale.
   * Defaults to the system's locale if no locale has been specified
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
   *
   * @param formatOpts - Intl.DateTimeFormat constructor options and configuration options
   * @param opts - opts to override the configuration options on this DateTime
   *
   * @example
   * DateTime.now().toLocaleString(); //=> 4/20/2017
   * @example
   * DateTime.now().setLocale('en-gb').toLocaleString(); //=> '20/04/2017'
   * @example
   * DateTime.now().toLocaleString({ locale: 'en-gb' }); //=> '20/04/2017'
   * @example
   * DateTime.now().toLocaleString(DateTime.DATE_FULL); //=> 'April 20, 2017'
   * @example
   * DateTime.now().toLocaleString(DateTime.TIME_SIMPLE); //=> '11:32 AM'
   * @example
   * DateTime.now().toLocaleString(DateTime.DATETIME_SHORT); //=> '4/20/2017, 11:32 AM'
   * @example
   * DateTime.now().toLocaleString({ weekday: 'long', month: 'long', day: '2-digit' }); //=> 'Thursday, April 20'
   * @example
   * DateTime.now().toLocaleString({ weekday: 'short', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit' }); //=> 'Thu, Apr 20, 11:27 AM'
   * @example
   * DateTime.now().toLocaleString({ hour: '2-digit', minute: '2-digit', hourCycle: 'h23' }); //=> '11:32'
   */
  toLocaleString(formatOpts?: DateTimeFormatOptions, opts?: LocaleOptions): string;

  /**
   * Returns an array of format "parts", meaning individual tokens along with metadata. This is allows callers to post-process individual sections of the formatted output.
   * Defaults to the system's locale if no locale has been specified
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat/formatToParts
   *
   * @example
   * DateTime.now().toLocaleParts(); //=> [
   *                                 //=>   { type: 'day', value: '25' },
   *                                 //=>   { type: 'literal', value: '/' },
   *                                 //=>   { type: 'month', value: '05' },
   *                                 //=>   { type: 'literal', value: '/' },
   *                                 //=>   { type: 'year', value: '1982' }
   *                                 //=> ]
   */
  toLocaleParts(opts?: DateTimeFormatOptions): Intl.DateTimeFormatPart[];

  /**
   * Returns an ISO 8601-compliant string representation of this DateTime
   *
   * @example
   * DateTime.utc(1982, 5, 25).toISO() //=> '1982-05-25T00:00:00.000Z'
   * @example
   * DateTime.now().toISO() //=> '2017-04-22T20:47:05.335-04:00'
   * @example
   * DateTime.now().toISO({ includeOffset: false }) //=> '2017-04-22T20:47:05.335'
   * @example
   * DateTime.now().toISO({ format: 'basic' }) //=> '20170422T204705.335-0400'
   */
  toISO(opts?: ToISOTimeOptions): string;

  /**
   * Returns an ISO 8601-compliant string representation of this DateTime's date component
   *
   * @param opts - options
   * @param opts.format - choose between the basic and extended format. Defaults to 'extended'.
   *
   * @example
   * DateTime.utc(1982, 5, 25).toISODate() //=> '1982-05-25'
   * @example
   * DateTime.utc(1982, 5, 25).toISODate({ format: 'basic' }) //=> '19820525'
   */
  toISODate(opts?: ToISODateOptions): string;

  /**
   * Returns an ISO 8601-compliant string representation of this DateTime's week date
   *
   * @example
   * DateTime.utc(1982, 5, 25).toISOWeekDate() //=> '1982-W21-2'
   */
  toISOWeekDate(): string;

  /**
   * Returns an ISO 8601-compliant string representation of this DateTime's time component
   *
   * @param opts - options
   * @param opts.suppressMilliseconds - exclude milliseconds from the format if they're 0. Defaults to false.
   * @param opts.suppressSeconds - exclude seconds from the format if they're 0. Defaults to false.
   * @param opts.includeOffset - include the offset, such as 'Z' or '-04:00'. Defaults to true.
   * @param opts.includePrefix - include the `T` prefix. Defaults to false.
   * @param opts.format - choose between the basic and extended format. Defaults to 'extended'.
   *
   * @example
   * DateTime.utc().set({ hour: 7, minute: 34 }).toISOTime() //=> '07:34:19.361Z'
   * @example
   * DateTime.utc().set({ hour: 7, minute: 34, seconds: 0, milliseconds: 0 }).toISOTime({ suppressSeconds: true }) //=> '07:34Z'
   * @example
   * DateTime.utc().set({ hour: 7, minute: 34 }).toISOTime({ format: 'basic' }) //=> '073419.361Z'
   * @example
   * DateTime.utc().set({ hour: 7, minute: 34 }).toISOTime({ includePrefix: true }) //=> 'T07:34:19.361Z'
   */
  toISOTime(opts?: ToISOTimeOptions): string;

  /**
   * Returns an RFC 2822-compatible string representation of this DateTime, always in UTC
   *
   * @example
   * DateTime.utc(2014, 7, 13).toRFC2822() //=> 'Sun, 13 Jul 2014 00:00:00 +0000'
   * @example
   * DateTime.local(2014, 7, 13).toRFC2822() //=> 'Sun, 13 Jul 2014 00:00:00 -0400'
   */
  toRFC2822(): string;

  /**
   * Returns a string representation of this DateTime appropriate for use in HTTP headers.
   * Specifically, the string conforms to RFC 1123.
   * @see https://www.w3.org/Protocols/rfc2616/rfc2616-sec3.html#sec3.3.1
   *
   * @example
   * DateTime.utc(2014, 7, 13).toHTTP() //=> 'Sun, 13 Jul 2014 00:00:00 GMT'
   * @example
   * DateTime.utc(2014, 7, 13, 19).toHTTP() //=> 'Sun, 13 Jul 2014 19:00:00 GMT'
   */
  toHTTP(): string;

  /**
   * Returns a string representation of this DateTime appropriate for use in SQL Date
   *
   * @example
   * DateTime.utc(2014, 7, 13).toSQLDate() //=> '2014-07-13'
   */
  toSQLDate(): string;

  /**
   * Returns a string representation of this DateTime appropriate for use in SQL Time
   *
   * @example
   * DateTime.utc().toSQL() //=> '05:15:16.345'
   * @example
   * DateTime.now().toSQL() //=> '05:15:16.345 -04:00'
   * @example
   * DateTime.now().toSQL({ includeOffset: false }) //=> '05:15:16.345'
   * @example
   * DateTime.now().toSQL({ includeZone: false }) //=> '05:15:16.345 America/New_York'
   */
  toSQLTime(opts?: ToSQLOptions): string;

  /**
   * Returns a string representation of this DateTime for use in SQL DateTime
   *
   * @example
   * DateTime.utc(2014, 7, 13).toSQL() //=> '2014-07-13 00:00:00.000 Z'
   * @example
   * DateTime.local(2014, 7, 13).toSQL() //=> '2014-07-13 00:00:00.000 -04:00'
   * @example
   * DateTime.local(2014, 7, 13).toSQL({ includeOffset: false }) //=> '2014-07-13 00:00:00.000'
   * @example
   * DateTime.local(2014, 7, 13).toSQL({ includeZone: true }) //=> '2014-07-13 00:00:00.000 America/New_York'
   */
  toSQL(opts?: ToSQLOptions): string;

  /**
   * Returns a string representation of this DateTime appropriate for debugging
   */
  toString(): string;

  /**
   * Returns the epoch milliseconds of this DateTime. Alias of {@link DateTime.toMillis}
   */
  valueOf(): number;

  /**
   * Returns the epoch milliseconds of this DateTime.
   */
  toMillis(): number;

  /**
   * Returns the epoch seconds of this DateTime.
   */
  toSeconds(): number;

  /**
   * Returns the epoch seconds (as a whole number) of this DateTime.
   */
  toUnixInteger(): number;

  /**
   * Returns an ISO 8601 representation of this DateTime appropriate for use in JSON.
   */
  toJSON(): string;

}

/**
 * Create a DateTime for the current instant, in the system's time zone.
 *
 * Use Settings to override these default values if needed.
 * @example
 * DateTime.now().toISO() //~> now in the ISO format
 */
function now(): DateTime {
  // now is always valid
  return LuxonDateTime.now() as DateTime;
}

function fromValidISO(text: string, opts?: DateTimeOptions): DateTime {
  return fromISO(text, { ...opts, throwOnInvalid: true });
}

/**
 * Create a DateTime from an ISO 8601 string
 *
 * @param text - the ISO string
 * @param opts - options to affect the creation
 * @param opts.zone - use this zone if no offset is specified in the input string itself. Will also convert the time to this zone. Defaults to 'local'.
 * @param opts.setZone - override the zone with a fixed-offset zone specified in the string itself, if it specifies one. Defaults to false.
 * @param opts.locale - a locale to set on the resulting DateTime instance. Defaults to 'system's locale'.
 * @param opts.outputCalendar - the output calendar to set on the resulting DateTime instance
 * @param opts.numberingSystem - the numbering system to set on the resulting DateTime instance
 *
 * @example
 * DateTime.fromISO('2016-05-25T09:08:34.123')
 * @example
 * DateTime.fromISO('2016-05-25T09:08:34.123+06:00')
 * @example
 * DateTime.fromISO('2016-05-25T09:08:34.123+06:00', {setZone: true})
 * @example
 * DateTime.fromISO('2016-05-25T09:08:34.123', {zone: 'utc'})
 * @example
 * DateTime.fromISO('2016-W05-4')
 */
function fromISO(text: string): DateTimeOrInvalid;
function fromISO<Options extends DateTimeOptions & DateTimeWrapperOptions>(text: string, opts: Options): OptDateTime<Options>;
function fromISO(text: string, opts?: DateTimeOptions & DateTimeWrapperOptions): DateTimeOrInvalid {
  const { throwOnInvalid, ...luxonOpts } = opts ?? {};
  const dateTime = LuxonDateTime.fromISO(text, luxonOpts);
  if (throwOnInvalid && !dateTime.isValid) {
    throw new InvalidDateTimeError(dateTime);
  }
  return dateTime;
}

/**
 * Create a DateTime from an input string and format string.
 * Defaults to en-US if no locale has been specified, regardless of the system's locale. For a table of tokens and their interpretations,
 * see [here](https://moment.github.io/luxon/#/parsing?id=table-of-tokens).
 *
 * @param text - the string to parse
 * @param fmt - the format the string is expected to be in (see the link below for the formats)
 * @param opts - options to affect the creation
 * @param opts.zone - use this zone if no offset is specified in the input string itself. Will also convert the DateTime to this zone. Defaults to 'local'.
 * @param opts.setZone - override the zone with a zone specified in the string itself, if it specifies one. Defaults to false.
 * @param opts.locale - a locale string to use when parsing. Will also set the DateTime to this locale. Defaults to 'en-US'.
 * @param opts.numberingSystem - the numbering system to use when parsing. Will also set the resulting DateTime to this numbering system
 * @param opts.outputCalendar - the output calendar to set on the resulting DateTime instance
 */
function fromFormat(text: string, fmt: string, opts?: LuxonDateTimeOptions): DateTimeOrInvalid;
function fromFormat<Options extends DateTimeOptions & DateTimeWrapperOptions>(text: string, fmt: string, opts: Options): OptDateTime<Options>;
function fromFormat(text: string, fmt: string, opts?: DateTimeOptions & DateTimeWrapperOptions): DateTimeOrInvalid {
  const { throwOnInvalid, ...luxonOpts } = opts ?? {};
  const dateTime = LuxonDateTime.fromFormat(text, fmt, luxonOpts);
  if (throwOnInvalid && !dateTime.isValid) {
    throw new InvalidDateTimeError(dateTime);
  }
  return dateTime;
}

/**
 * Create a DateTime from a JavaScript Date object. Uses the default zone.
 *
 * @param date - a JavaScript Date object
 * @param options - configuration options for the DateTime
 * @param options.zone - the zone to place the DateTime into
 */
function fromJSDate(date: Date, options?: { zone?: string | Zone }): DateTimeOrInvalid;
function fromJSDate<Options extends { zone?: string | Zone } & DateTimeWrapperOptions>(date: Date, options: Options): OptDateTime<Options>;
function fromJSDate(date: Date, options?: { zone?: string | Zone } & DateTimeWrapperOptions): DateTimeOrInvalid {
  const { throwOnInvalid, ...luxonOptions } = options ?? {};
  const dateTime = LuxonDateTime.fromJSDate(date, luxonOptions);
  if (throwOnInvalid && !dateTime.isValid) {
    throw new InvalidDateTimeError(dateTime);
  }
  return dateTime;
}

/**
 * Create a DateTime from a number of milliseconds since the epoch (meaning since 1 January 1970 00:00:00 UTC). Uses the default zone.
 *
 * @param milliseconds - a number of milliseconds since 1970 UTC
 * @param options - configuration options for the DateTime
 * @param options.zone - the zone to place the DateTime into. Defaults to 'local'.
 * @param options.locale - a locale to set on the resulting DateTime instance
 * @param options.outputCalendar - the output calendar to set on the resulting DateTime instance
 * @param options.numberingSystem - the numbering system to set on the resulting DateTime instance
 */
function fromMillis(milliseconds: number, options?: DateTimeJSOptions): DateTimeOrInvalid;
function fromMillis<Options extends DateTimeOptions & DateTimeWrapperOptions>(milliseconds: number, options: Options): OptDateTime<Options>;
function fromMillis(milliseconds: number, options?: DateTimeOptions & DateTimeWrapperOptions): DateTimeOrInvalid {
  const { throwOnInvalid, ...luxonOptions } = options ?? {};
  const dateTime = LuxonDateTime.fromMillis(milliseconds, luxonOptions);
  if (throwOnInvalid && !dateTime.isValid) {
    throw new InvalidDateTimeError(dateTime);
  }
  return dateTime;
}

/**
 * Create a DateTime from a JavaScript object with keys like 'year' and 'hour' with reasonable defaults.
 *
 * @param obj - the object to create the DateTime from
 * @param obj.year - a year, such as 1987
 * @param obj.month - a month, 1-12
 * @param obj.day - a day of the month, 1-31, depending on the month
 * @param obj.ordinal - day of the year, 1-365 or 366
 * @param obj.weekYear - an ISO week year
 * @param obj.weekNumber - an ISO week number, between 1 and 52 or 53, depending on the year
 * @param obj.weekday - an ISO weekday, 1-7, where 1 is Monday and 7 is Sunday
 * @param obj.hour - hour of the day, 0-23
 * @param obj.minute - minute of the hour, 0-59
 * @param obj.second - second of the minute, 0-59
 * @param obj.millisecond - millisecond of the second, 0-999
 * @param opts - options for creating this DateTime
 * @param opts.zone - interpret the numbers in the context of a particular zone. Can take any value taken as the first argument to setZone(). Defaults to 'local'.
 * @param opts.locale - a locale to set on the resulting DateTime instance. Defaults to 'system's locale'.
 * @param opts.outputCalendar - the output calendar to set on the resulting DateTime instance
 * @param opts.numberingSystem - the numbering system to set on the resulting DateTime instance
 *
 * @example
 * DateTime.fromObject({ year: 1982, month: 5, day: 25}).toISODate() //=> '1982-05-25'
 * @example
 * DateTime.fromObject({ year: 1982 }).toISODate() //=> '1982-01-01'
 * @example
 * DateTime.fromObject({ hour: 10, minute: 26, second: 6 }) //~> today at 10:26:06
 * @example
 * DateTime.fromObject({ hour: 10, minute: 26, second: 6 }, { zone: 'utc' }),
 * @example
 * DateTime.fromObject({ hour: 10, minute: 26, second: 6 }, { zone: 'local' })
 * @example
 * DateTime.fromObject({ hour: 10, minute: 26, second: 6 }, { }zone: 'America/New_York' })
 * @example
 * DateTime.fromObject({ weekYear: 2016, weekNumber: 2, weekday: 3 }).toISODate() //=> '2016-01-13'
 */
function fromObject(obj: DateObjectUnits, opts?: DateTimeJSOptions & DateTimeWrapperOptions): DateTimeOrInvalid;
function fromObject<Options extends DateTimeJSOptions & DateTimeWrapperOptions>(obj: DateObjectUnits, opts?: Options): OptDateTime<Options>;
function fromObject(obj: DateObjectUnits, opts?: DateTimeJSOptions & DateTimeWrapperOptions): DateTimeOrInvalid {
  const { throwOnInvalid, ...luxonOpts } = opts ?? {};
  const dateTime = LuxonDateTime.fromObject(obj, luxonOpts);
  if (throwOnInvalid && !dateTime.isValid) {
    throw new InvalidDateTimeError(dateTime);
  }
  return dateTime;
}

function isDateTime(dateTime: unknown): dateTime is DateTimeOrInvalid {
  return LuxonDateTime.isDateTime(dateTime);
}

function isValid(dateTime: DateTimeOrInvalid): dateTime is DateTime {
  return dateTime.isValid;
}

function assertValid(dateTime: DateTimeOrInvalid): DateTime {
  if (!dateTime.isValid) {
    throw new InvalidDateTimeError(dateTime);
  }
  return dateTime as DateTime;
}

function validOrNull(dateTime: DateTimeOrInvalid): DateTime | null {
  if (!dateTime.isValid) {
    return null;
  }
  return dateTime as DateTime;
}

function min(...dateTimes: DateTime[]): DateTime {
  // min of valid dateTimes is valid
  return LuxonDateTime.min(...dateTimes) as DateTime;
}

function max(...dateTimes: DateTime[]): DateTime {
  // max of valid dateTimes is valid
  return LuxonDateTime.max(...dateTimes) as DateTime;
}

export const DateTime = {
  now,
  fromISO,
  fromValidISO,
  fromFormat,
  fromJSDate,
  fromMillis,
  fromObject,
  isDateTime,
  isValid,
  assertValid,
  validOrNull,
  min,
  max,

  /**
   * {@link DateTime.toLocaleString} format like 'Oct 14, 1983'
   */
  DATE_MED: LuxonDateTime.DATE_MED,

  /**
   * {@link DateTime.toLocaleString} format like 'Fri, Oct 14, 1983'
   */
  DATE_MED_WITH_WEEKDAY: LuxonDateTime.DATE_MED_WITH_WEEKDAY,

  /**
   * {@link DateTime.toLocaleString} format like 'October 14, 1983'
   */
  DATE_FULL: LuxonDateTime.DATE_FULL,

  /**
   * {@link DateTime.toLocaleString} format like 'Tuesday, October 14, 1983'
   */
  DATE_HUGE: LuxonDateTime.DATE_HUGE,

  /**
   * {@link DateTime.toLocaleString} format like '09:30 AM'. Only 12-hour if the locale is.
   */
  TIME_SIMPLE: LuxonDateTime.TIME_SIMPLE,

  /**
   * {@link DateTime.toLocaleString} format like '09:30:23 AM'. Only 12-hour if the locale is.
   */
  TIME_WITH_SECONDS: LuxonDateTime.TIME_WITH_SECONDS,

  /**
   * {@link DateTime.toLocaleString} format like '09:30:23 AM EDT'. Only 12-hour if the locale is.
   */
  TIME_WITH_SHORT_OFFSET: LuxonDateTime.TIME_WITH_SHORT_OFFSET,

  /**
   * {@link DateTime.toLocaleString} format like '09:30:23 AM Eastern Daylight Time'. Only 12-hour if the locale is.
   */
  TIME_WITH_LONG_OFFSET: LuxonDateTime.TIME_WITH_LONG_OFFSET,

  /**
   * {@link DateTime.toLocaleString} format like '09:30', always 24-hour.
   */
  TIME_24_SIMPLE: LuxonDateTime.TIME_24_SIMPLE,

  /**
   * {@link DateTime.toLocaleString} format like '09:30:23', always 24-hour.
   */
  TIME_24_WITH_SECONDS: LuxonDateTime.TIME_24_WITH_SECONDS,

  /**
   * {@link DateTime.toLocaleString} format like '09:30:23 EDT', always 24-hour.
   */
  TIME_24_WITH_SHORT_OFFSET: LuxonDateTime.TIME_24_WITH_SHORT_OFFSET,

  /**
   * {@link DateTime.toLocaleString} format like '09:30:23 Eastern Daylight Time', always 24-hour.
   */
  TIME_24_WITH_LONG_OFFSET: LuxonDateTime.TIME_24_WITH_LONG_OFFSET,

  /**
   * {@link DateTime.toLocaleString} format like '10/14/1983, 9:30 AM'. Only 12-hour if the locale is.
   */
  DATETIME_SHORT: LuxonDateTime.DATETIME_SHORT,

  /**
   * {@link DateTime.toLocaleString} format like '10/14/1983, 9:30:33 AM'. Only 12-hour if the locale is.
   */
  DATETIME_SHORT_WITH_SECONDS: LuxonDateTime.DATETIME_SHORT_WITH_SECONDS,

  /**
   * {@link DateTime.toLocaleString} format like 'Oct 14, 1983, 9:30 AM'. Only 12-hour if the locale is.
   */
  DATETIME_MED: LuxonDateTime.DATETIME_MED,

  /**
   * {@link DateTime.toLocaleString} format like 'Oct 14, 1983, 9:30:33 AM'. Only 12-hour if the locale is.
   */
  DATETIME_MED_WITH_SECONDS: LuxonDateTime.DATETIME_MED_WITH_SECONDS,

  /**
   * {@link DateTime.toLocaleString} format like 'Fri, 14 Oct 1983, 9:30 AM'. Only 12-hour if the locale is.
   */
  DATETIME_MED_WITH_WEEKDAY: LuxonDateTime.DATETIME_MED_WITH_WEEKDAY,

  /**
   * {@link DateTime.toLocaleString} format like 'October 14, 1983, 9:30 AM EDT'. Only 12-hour if the locale is.
   */
  DATETIME_FULL: LuxonDateTime.DATETIME_FULL,

  /**
   * {@link DateTime.toLocaleString} format like 'October 14, 1983, 9:30:33 AM EDT'. Only 12-hour if the locale is.
   */
  DATETIME_FULL_WITH_SECONDS: LuxonDateTime.DATETIME_FULL_WITH_SECONDS,

  /**
   * {@link DateTime.toLocaleString} format like 'Friday, October 14, 1983, 9:30 AM Eastern Daylight Time'. Only 12-hour if the locale is.
   */
  DATETIME_HUGE: LuxonDateTime.DATETIME_HUGE,

  /**
   * {@link DateTime.toLocaleString} format like 'Friday, October 14, 1983, 9:30:33 AM Eastern Daylight Time'. Only 12-hour if the locale is.
   */
  DATETIME_HUGE_WITH_SECONDS: LuxonDateTime.DATETIME_HUGE_WITH_SECONDS,
};

// make the runtime type accessible for tests/instanceof checks
export const DateTimeRuntimeType = LuxonDateTime;
