import { Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { isDoctariUserRole } from '@@common-permissions/roles';
import { IMAGES } from '@@frontend/assets';
import useAuthContext from '@@frontend/hooks/useAuthContext';

import useStyles from './styles';

import type { Role } from '@@common-permissions/AccessControl';

interface ErrorProps {
  error?: any;
}

export const UncaughtUIError = ({ error }: ErrorProps): JSX.Element => {
  const authContext = useAuthContext();
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <Paper className={ classes.pageError }>
      <Typography variant="body1">
        { t('common.error') }
      </Typography>
      <img
        className={ classes.icon }
        src={ IMAGES.SAD_FACE }
        alt="sad_face"
      />

      { isDoctariUserRole(authContext.userPrincipal?.role as Role) && error && (
        <div>
          { error.toString() }
        </div>
      ) }

    </Paper>
  );
};
