import TagManager from 'react-gtm-module';

import { DEFAULT_COOKIE_ACCEPTED_EVENT } from '@@frontend/constants/cookies';
import { acceptedCookies, getCookieValue } from '@@frontend/utils/cookies';

const _initGoogleTagManager = (): void => {
  const gtmId = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;
  const auth = process.env.REACT_APP_GOOGLE_TAG_MANAGER_AUTH;
  const preview = process.env.REACT_APP_GOOGLE_TAG_MANAGER_PREVIEW;

  if (process.env.NODE_ENV === 'production' && gtmId) {
    TagManager.initialize({
      gtmId,
      auth,
      preview,
    });
  }

  window.removeEventListener(DEFAULT_COOKIE_ACCEPTED_EVENT, _initGoogleTagManager);
};

if (acceptedCookies()) {
  _initGoogleTagManager();
} else if (!getCookieValue()) {
  window.addEventListener(DEFAULT_COOKIE_ACCEPTED_EVENT, _initGoogleTagManager, false);
}
