import { createTheme } from '@mui/material/styles';

import './animations.css';
import './custom-overrides.css';
import './fonts.css';
import './loader.css';
import './main.css';

export const COLORS = {
  WHITE: '#ffffff',
  BACKGROUND: '#f8fafc',
  BORDER: '#dddddd',
  BORDER_LIGHT: '#eeeeee',
  ERROR: '#ff7577',
  HEADLINE: '#264b66',
  NAVIGATION_ACTIVE_COLOR: '#264b66',
  NAVIGATION_BACKGROUND: '#d1e9ff',
  PRIMARY: '#264b66',
  SECONDARY: '#67b8ff',
  SECONDARY_LIGHT: '#9bd0ff',
  SUCCESS: '#97cc64',
  INFO: '#67b8ff',
  TEXT: {
    PRIMARY: '#264b66',
    SECONDARY: '#9b9b9b',
  },
  WARNING: '#cecf62',
  WARNING_SECONDARY: '#cecf62',
};

export const theme = createTheme({
  customPalette: {
    border: {
      light: COLORS.BORDER_LIGHT,
      main: COLORS.BORDER,
    },
    navigationActiveColor: COLORS.NAVIGATION_ACTIVE_COLOR,
    navigationBackground: COLORS.NAVIGATION_BACKGROUND,
  },
  palette: {
    background: {
      default: COLORS.BACKGROUND,
    },
    text: {
      primary: COLORS.TEXT.PRIMARY,
      secondary: COLORS.TEXT.SECONDARY,
    },
    primary: {
      main: COLORS.PRIMARY,

    },
    secondary: {
      main: COLORS.SECONDARY,
    },
    error: {
      main: COLORS.ERROR,
    },
    success: {
      main: COLORS.SUCCESS,
    },
    info: {
      main: COLORS.INFO,
    },
    warning: {
      main: COLORS.WARNING,
      light: COLORS.WARNING_SECONDARY,
    },
  },
  components: {
    MuiBadge: {
      styleOverrides: {
        colorSecondary: {
          color: 'white',
          backgroundColor: COLORS.ERROR,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 24,
          padding: '15.5px 32px !important',
          fontSize: '15px !important',
          fontWeight: 600,
          lineHeight: '17px',
          letterSpacing: 0.3,
          textTransform: 'none',

          '&.loading': {
            padding: '11px 32px !important',
          },
        },
        contained: {
          boxShadow: 'none',
        },
        containedPrimary: {
          color: 'white',
          boxShadow: 'none',

          '&:hover': {
            backgroundColor: 'white',
            color: COLORS.PRIMARY,
            // on doctari website it is "inset 0 0 0 0.1em ...", but it looks asymmetric on Safari and Edge
            boxShadow: `inset 0 0 0 2px ${COLORS.PRIMARY}`,
          },
        },
        containedSecondary: {
          color: 'white',
          boxShadow: 'none',

          '&:hover': {
            backgroundColor: 'white',
            color: COLORS.SECONDARY,
            // on doctari website it is "inset 0 0 0 0.1em ...", but it looks asymmetric on Safari and Edge
            boxShadow: `inset 0 0 0 2px ${COLORS.SECONDARY}`,
          },
        },
        outlined: {
          fontWeight: 600,

          '&:hover': {
            backgroundColor: 'transparent',
            boxShadow: '0 0 0 1px',
          },
        },
        outlinedPrimary: {
          borderColor: COLORS.PRIMARY,
          '&:hover': {
            backgroundColor: 'transparent',
            boxShadow: '0 0 0 1px',
          },
        },
        outlinedSecondary: {
          borderColor: COLORS.SECONDARY,
          '&:hover': {
            backgroundColor: 'transparent',
            boxShadow: '0 0 0 1px',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            fill: 'currentColor',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          fontSize: 14,
          letterSpacing: -0.44,
          fontWeight: 600,
        },

        colorSecondary: {
          color: 'white',
        },
        deleteIconColorSecondary: {
          color: 'rgba(255, 255, 255, 0.5)',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          alignItems: 'flex-start',
          marginRight: 0,
        },
        label: {
          marginTop: 10,
          marginLeft: 7,
          fontWeight: 600,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          position: 'absolute',
          width: '100%',
          marginLeft: '0 !important',
          bottom: -22,
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          fill: COLORS.TEXT.PRIMARY,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fill: COLORS.TEXT.PRIMARY,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 6,
        },
        notchedOutline: {
          borderColor: COLORS.BORDER,
          transition: 'border-color 0.3s',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.05)',
        },
        rounded: {
          borderRadius: 8,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            fill: 'currentColor',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: 15,
          borderTopColor: COLORS.BORDER_LIGHT,
          borderRightColor: COLORS.BORDER_LIGHT,
          borderBottomColor: COLORS.BORDER_LIGHT,
          borderLeftColor: COLORS.BORDER_LIGHT,
        },
        head: {
          fontWeight: 600,
        },
        sizeSmall: {
          padding: '5px 15px',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:last-child .MuiTableCell-root': {
            borderBottom: 'none',
          },
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
        placement: 'right',
      },
      styleOverrides: {
        tooltip: {
          backgroundColor: COLORS.BACKGROUND,
          color: COLORS.TEXT.PRIMARY,
          border: `1px solid ${COLORS.BORDER}`,
          borderRadius: 8,
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.05)',
          fontSize: 14,
        },
        arrow: {
          fontSize: 14,
          '&::before': {
            border: `1px solid ${COLORS.BORDER}`,
            backgroundColor: COLORS.BACKGROUND,
            boxSizing: 'border-box',
          },
        },
      },
    },
  },
  spacing: 7.5,
  typography: {
    fontFamily: 'Gilroy, Helvetica',
    h1: {
      color: COLORS.HEADLINE,
    },
    h2: {
      color: COLORS.HEADLINE,
    },
    h3: {
      color: COLORS.HEADLINE,
    },
    h4: {
      color: COLORS.HEADLINE,
      fontSize: 32,
      fontWeight: 600,
      lineHeight: 1.5,
      letterSpacing: -0.7,
    },
    h5: {
      color: COLORS.HEADLINE,
      fontSize: 24,
      fontWeight: 600,
    },
    h6: {
      color: COLORS.HEADLINE,
      fontSize: 18,
    },
  },
});
