import { createContext, useMemo, useState } from 'react';

import type { CandidatePrincipal, UserPrincipal } from '@@frontend/services/auth/PrincipalService';
import type { ReactElement } from 'react';

export interface UserPrincipalSettings {
  hasExternalIntegrationEnabled: boolean
}

export interface AuthContextProps {
  userPrincipal: UserPrincipal | null,
  setUserPrincipal: (userPrincipal: UserPrincipal | null) => void,
  userPrincipalSettings: UserPrincipalSettings | null,
  setUserPrincipalSettings: (userPrincipalSettings: UserPrincipalSettings | null) => void,
  candidatePrincipal: CandidatePrincipal | null,
  setCandidatePrincipal: (candidatePrincipal: CandidatePrincipal | null) => void,
}

export const AuthContext = createContext<AuthContextProps | null>(null);

const AuthContextProvider = ({ children }: { children: ReactElement }): ReactElement => {
  const [userPrincipal, setUserPrincipal] = useState<UserPrincipal | null>(null);
  const [userPrincipalSettings, setUserPrincipalSettings] = useState<UserPrincipalSettings | null>(null);
  const [candidatePrincipal, setCandidatePrincipal] = useState<CandidatePrincipal | null>(null);

  const contextValue = useMemo(() => ({
    userPrincipal,
    setUserPrincipal,
    userPrincipalSettings,
    setUserPrincipalSettings,
    candidatePrincipal,
    setCandidatePrincipal,
  }), [userPrincipal, setUserPrincipal, userPrincipalSettings, setUserPrincipalSettings, candidatePrincipal, setCandidatePrincipal]);

  return (
    <AuthContext.Provider
      value={ contextValue }>
      { children }
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
