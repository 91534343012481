/* eslint-disable no-nested-ternary,max-classes-per-file */

import {
  DateTime as LuxonDateTime,
} from 'luxon';

import { compareAll, naturalOrder, reversed } from '@@util/core/sort';
import { LocalDateRange } from '@@util/date/LocalDateRange';
import { InvalidLocalDateError } from '@@util/date/errors';

import { DateTime } from './DateTime';

import type { DateTimeOrInvalid } from './DateTime';
import type { Comparator } from '@@util/core/sort';
import type {
  DateTimeFormatOptions,
  Duration,
  DurationLike,
  LocaleOptions,
  DateTimeUnit as LuxonDateTimeUnit,
  DurationLikeObject as LuxonDurationLikeObject,
  Zone,
} from 'luxon';

const RE_ISO = /\d{4}-\d{2}-\d{2}/;

export type DurationObjectUnits = Pick<LuxonDurationLikeObject,
  'year' | 'years' |
  'quarter' | 'quarters' |
  'month' | 'months' |
  'week' | 'weeks' |
  'day' | 'days'
>;
export type DurationUnit = keyof DurationObjectUnits;
export type DurationUnits = DurationUnit | DurationUnit[];
export type LocalDateUnit = Extract<LuxonDateTimeUnit, 'year' | 'quarter' | 'month' | 'week' | 'day'>

type LocalDateOptions = {
  throwOnInvalid?: boolean;
}

type OptLocalDate<Options extends LocalDateOptions> = Options extends { throwOnInvalid: true } ? LocalDate : LocalDateOrInvalid;

export interface LocalDateOrInvalid {
  isValid(): this is LocalDate;

  assertValid(): LocalDate;

  validOrNull(): LocalDate | null;

  /**
   * Returns a string representation of this LocalDate formatted according to the specified format string.
   *
   * If this LocalDate is invalid, the string 'Invalid LocalDate' is returned.
   *
   * See also:
   * https://moment.github.io/luxon/#/formatting?id=table-of-tokens
   */
  toFormat(formatPattern: string, options?: LocaleOptions & DateTimeFormatOptions): string | 'Invalid LocalDate';

  toISO(): string | null;

  toDateTime(): DateTimeOrInvalid;
}

export class InvalidLocalDate implements LocalDateOrInvalid {
  // duck-typing flag to avoid instanceof checks (which can break across context boundaries)
  private readonly isDoctariLocalDateInvalid = true;

  constructor(private readonly dateTime: LuxonDateTime) {
    if (dateTime.isValid) {
      throw new Error('InvalidLocalDate can only be created from an invalid DateTime');
    }
  }

  static isInvalidLocalDate(value: unknown): value is InvalidLocalDate {
    // noinspection PointlessBooleanExpressionJS
    return !!value && !!(value as InvalidLocalDate).isDoctariLocalDateInvalid;
  }

  assertValid(): never {
    const { invalidExplanation, invalidReason } = this.dateTime;
    const message = invalidExplanation ? `${invalidReason}: ${invalidExplanation}` : `${invalidReason}`;

    throw new InvalidLocalDateError(message);
  }

  // eslint-disable-next-line class-methods-use-this
  isValid(): false {
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  validOrNull(): LocalDate | null {
    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  toFormat(): 'Invalid LocalDate' {
    return 'Invalid LocalDate';
  }

  toDateTime(): DateTimeOrInvalid {
    return this.dateTime;
  }

  // eslint-disable-next-line class-methods-use-this
  toISO(): null {
    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  equals(other: LocalDateOrInvalid): boolean {
    return false;
  }
}

export class LocalDate implements LocalDateOrInvalid {
  private readonly dateTime: DateTime;

  // duck-typing flag to avoid instanceof checks (which can break across context boundaries)
  private readonly isDoctariLocalDate = true;

  private constructor(dateTime: LuxonDateTime) {
    this.dateTime = DateTime.assertValid(dateTime.startOf('day'));
  }

  static fromDateTime(dateTime: DateTime): LocalDate;
  static fromDateTime(dateTime: DateTimeOrInvalid): LocalDateOrInvalid;
  static fromDateTime<Options extends LocalDateOptions>(dateTime: DateTimeOrInvalid, options: Options): OptLocalDate<Options>;
  static fromDateTime(dateTime: DateTimeOrInvalid, options?: LocalDateOptions): LocalDateOrInvalid;
  static fromDateTime(dateTime: DateTimeOrInvalid, options?: LocalDateOptions): LocalDateOrInvalid {
    const result = dateTime.isValid ? new LocalDate(dateTime) : new InvalidLocalDate(dateTime as LuxonDateTime);
    if (options?.throwOnInvalid) {
      result.assertValid();
    }
    return result;
  }

  static isLocalDate(value: unknown): value is LocalDate {
    // noinspection PointlessBooleanExpressionJS
    return !!value && !!(value as LocalDate).isDoctariLocalDate;
  }

  static isLocalDateOrInvalid(value: unknown): value is LocalDateOrInvalid {
    return LocalDate.isLocalDate(value) || InvalidLocalDate.isInvalidLocalDate(value);
  }

  static today(): LocalDate {
    return LocalDate.fromDateTime(DateTime.now()).assertValid();
  }

  static fromValidISO(text: string): LocalDate {
    return LocalDate.fromISO(text, { throwOnInvalid: true });
  }

  static fromISO(text: string): LocalDateOrInvalid;
  static fromISO<Options extends LocalDateOptions>(text: string, options: Options): OptLocalDate<Options>;
  static fromISO(text: string, options?: LocalDateOptions): LocalDateOrInvalid {
    if (!LocalDate.isISOFormat(text)) {
      return LocalDate.fromDateTime(LuxonDateTime.invalid('Unsupported format (must be YYYY-MM-DD)'), options);
    }
    return LocalDate.fromDateTime(DateTime.fromISO(text), options);
  }

  static isISOFormat(text: string): boolean {
    return RE_ISO.test(text);
  }

  /**
   * Create a LocalDate from an input string and format string.
   * Defaults to en-US if no locale has been specified, regardless of the system's locale.
   *
   * See also:
   * https://moment.github.io/luxon/#/formatting?id=table-of-tokens
   */
  static fromFormat(text: string, formatPattern: string): LocalDateOrInvalid;
  static fromFormat<Options extends LocalDateOptions>(text: string, formatPattern: string, options: Options): OptLocalDate<Options>;
  static fromFormat(text: string, formatPattern: string, options?: LocalDateOptions): LocalDateOrInvalid;
  static fromFormat(text: string, formatPattern: string, options?: LocalDateOptions): LocalDateOrInvalid {
    return LocalDate.fromDateTime(LuxonDateTime.fromFormat(text, formatPattern), options);
  }

  static fromJSDate(date: Date): LocalDateOrInvalid;
  static fromJSDate<Options extends LocalDateOptions>(date: Date, options: Options): OptLocalDate<Options>;
  static fromJSDate(date: Date, options?: LocalDateOptions): LocalDateOrInvalid;
  static fromJSDate(date: Date, options?: LocalDateOptions): LocalDateOrInvalid {
    return LocalDate.fromDateTime(LuxonDateTime.fromJSDate(date), options);
  }

  static invalid(reason: string, explanation?: string): InvalidLocalDate {
    return new InvalidLocalDate(LuxonDateTime.invalid(reason, explanation));
  }

  toJSON(): string {
    return this.toISO();
  }

  toString(): string {
    return this.toISO();
  }

  // eslint-disable-next-line class-methods-use-this
  isValid(): this is LocalDate {
    return true;
  }

  assertValid(): this {
    return this;
  }

  validOrNull(): this {
    return this;
  }

  toDateTime(options: { zone?: string | Zone } = {}): DateTime {
    if (options.zone !== undefined) {
      return DateTime.assertValid(this.dateTime.setZone(options.zone, { keepLocalTime: true }));
    }
    return DateTime.assertValid(this.dateTime);
  }

  toISO(): string {
    return this.dateTime.toISODate()!;
  }

  /**
    * Returns a string representation of this LocalDate formatted according to the specified format string.
    *
    * See also:
    * https://moment.github.io/luxon/#/formatting?id=table-of-tokens
    */
  toFormat(formatPattern: string, options?: LocaleOptions & DateTimeFormatOptions): string {
    return this.dateTime.toFormat(formatPattern, options);
  }

  toJSDate(keepLocalTime = true): Date {
    return this.dateTime.setZone('UTC', { keepLocalTime }).toJSDate();
  }

  valueOf(): number {
    return this.dateTime.valueOf();
  }

  minus(duration: DurationLike): LocalDate {
    return LocalDate.fromDateTime(this.dateTime.minus(duration), { throwOnInvalid: true });
  }

  plus(duration: DurationLike): LocalDate {
    return LocalDate.fromDateTime(this.dateTime.plus(duration), { throwOnInvalid: true });
  }

  startOf(unit: LocalDateUnit): LocalDate {
    return LocalDate.fromDateTime(this.dateTime.startOf(unit), { throwOnInvalid: true });
  }

  endOf(unit: LocalDateUnit): LocalDate {
    return LocalDate.fromDateTime(this.dateTime.endOf(unit), { throwOnInvalid: true });
  }

  diff(other: LocalDate, unit: DurationUnits = ['days']): Duration {
    return this.dateTime.diff(other.dateTime, unit);
  }

  hasSame(other: LocalDate, unit: LocalDateUnit): boolean {
    return this.dateTime.hasSame(other.dateTime, unit);
  }

  equals(other: LocalDateOrInvalid): boolean {
    if (!other.isValid()) return false;
    return this.dateTime.equals(other.dateTime);
  }

  until(end: LocalDate): LocalDateRange {
    return LocalDateRange.between(this, end);
  }

  day(): number {
    return this.dateTime.day;
  }

  /**
   * Get the day of the week. 1 is Monday and 7 is Sunday
   */
  weekday(): number {
    return this.dateTime.weekday;
  }

  weekNumber(): number {
    return this.dateTime.weekNumber;
  }

  quarter(): number {
    return this.dateTime.quarter;
  }

  year(): number {
    return this.dateTime.year;
  }

  monthNumber(): number {
    return this.dateTime.month;
  }

  monthShortName(): string {
    return this.dateTime.monthShort;
  }

  setLocale(locale: string): LocalDate {
    return LocalDate.fromDateTime(this.dateTime.setLocale(locale), { throwOnInvalid: true });
  }

  /**
   * compare function that sorts invalid dates last. All other values are seen as equal.
   * @param a
   * @param b
   */
  static sortInvalidLast: Comparator<LocalDateOrInvalid> = (a, b): number => {
    const aValid = a.isValid();
    const bValid = b.isValid();
    if (!aValid || !bValid) {
      return !bValid ? aValid ? -1 : 0 : 1;
    }
    return 0;
  };

  /**
   * Compare function that sorts undefined and invalid dates last. All other values are seen as equal.
   * @param a LocalDate | undefined
   * @param b LocalDate | undefined
   * @returns {number}
   */
  static sortUndefinedLast: Comparator<LocalDateOrInvalid | undefined> = (a, b): number => {
    const aDefined = !!a;
    const bDefined = !!b;

    if (aDefined && bDefined) {
      return LocalDate.sortInvalidLast(a, b);
    }

    return !bDefined ? aDefined ? -1 : 0 : 1;
  };

  static sortOldestFirst: Comparator<LocalDateOrInvalid> = compareAll(LocalDate.sortInvalidLast, naturalOrder);

  static sortNewestFirst: Comparator<LocalDateOrInvalid> = compareAll(LocalDate.sortInvalidLast, reversed(naturalOrder));

  /**
   * Return the max of several local dates.
   * @param {...LocalDate[]} localDates - the LocalDates from which to choose the maximum
   * @return {LocalDate} the max LocalDate
   * @throws an Error, if called with an empty array
   */
  static max(...localDates: LocalDate[]): LocalDate {
    return LocalDate.reduce((p, v) => (p > v ? p : v), localDates);
  }

  /**
   * Return the min of several local dates.
   * @param {...LocalDate[]} localDates - the LocalDates from which to choose the maximum
   * @return {LocalDate} the min LocalDate
   * @throws an Error, if called with an empty array
   */
  static min(...localDates: LocalDate[]): LocalDate {
    return LocalDate.reduce((p, v) => (p < v ? p : v), localDates);
  }

  private static reduce(comparator: (p: LocalDate, v: LocalDate) => LocalDate, localDates: LocalDate[]): LocalDate {
    // moment.max returned the current date for an empty array, which made no real sense, as it was not even contained in the array;
    // thus we expect the code to handle this special case before.
    if (localDates.length === 0) {
      throw new Error('Empty array not allowed');
    }

    return localDates.reduce(comparator);
  }
}
