import { Button } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { detect } from 'detect-browser';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Dialog from '@@frontend/components/Dialog';
import PaperContainer from '@@frontend/components/PaperContainer';

import useStyles from './styles';

import type { FC } from 'react';

const BrowserInfo: FC = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const [browserInfo, setBrowserInfo] = useState<string>();
  const [showBrowserNotSupported, setShowBrowserNotSupported] = useState(false);

  const detectBrowser = useCallback((): void => {
    const browser = detect();

    setBrowserInfo(browser ? `${browser.name} v.${browser?.version} (${browser?.os})` : t('browser_info.unknown_browser'));

    switch (browser && browser.name) {
      case 'ios':
        if (browser?.version && parseFloat(browser?.version) < 11) {
          setShowBrowserNotSupported(true);
        }
        break;
      case 'chrome':
        if (browser?.version && parseFloat(browser?.version) < 78) {
          setShowBrowserNotSupported(true);
        }
        break;
      case 'firefox':
        if (browser?.version && parseFloat(browser?.version) < 69) {
          setShowBrowserNotSupported(true);
        }
        break;
      case 'safari':
        if (browser?.version && parseFloat(browser?.version) < 14) {
          setShowBrowserNotSupported(true);
        }
        break;
      case 'edge-chromium':
        if (browser?.version && parseFloat(browser?.version) < 81) {
          setShowBrowserNotSupported(true);
        }
        break;

      default:
        setShowBrowserNotSupported(true);
    }
  }, [t, setBrowserInfo, setShowBrowserNotSupported]);

  useEffect(() => {
    detectBrowser();
  }, [detectBrowser]);

  return (
    <Dialog
      disableEscapeKeyDown
      open={ showBrowserNotSupported }
      PaperComponent={ PaperContainer }
      PaperProps={ {
        className: classes.paper,
      } }>
      <DialogTitle className={ classes.dialogTitle }>
        { t('browser_info.header') }
      </DialogTitle>
      <DialogContent className={ classes.dialogContent }>
        <Typography
          variant="body1"
        >
          { t('browser_info.description') }
        </Typography>
        <Typography
          className={ classes['dialogContent-headline'] }
          variant="body1"
        >
          { t('browser_info.description_2_headline') }
        </Typography>
        <Typography
          variant="body1"
        >
          { t('browser_info.description_2') }
        </Typography>
        <Typography
          className={ classes['dialogContent-headline'] }
          variant="body1"
        >
          { t('browser_info.description_3_headline') }
        </Typography>
        <Typography
          variant="subtitle1"
        >
          { browserInfo }
        </Typography>
      </DialogContent>
      <DialogActions className={ classes.dialogActions }>
        <Button
          color="secondary"
          onClick={ () => setShowBrowserNotSupported(false) }
          size="large"
          variant="contained"
        >
          { t('common.confirm') }
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BrowserInfo;
