import { createContext } from 'react';

import AuthContextProvider from '@@frontend/context/AuthContextProvider';

import ErrorUtil from './utils/ErrorUtil';

import type { FC, ReactElement } from 'react';

const errorUtil = new ErrorUtil();

export const ErrorUtilContext = createContext(errorUtil);

interface ContextProviderProps {
  children: ReactElement;
}

const ContextProvider: FC<ContextProviderProps> = ({ children }: { children: ReactElement }): ReactElement => {
  return (
    <ErrorUtilContext.Provider value={ errorUtil }>
      <AuthContextProvider>
        { children }
      </AuthContextProvider>
    </ErrorUtilContext.Provider>
  );
};

export default ContextProvider;
