import APPROVED_CHECKED from './animations/approved-checked.json';
import OPENING_ENVELOPE from './animations/opening-envelope.json';
import womensday1 from './img/banner-womensday1.png';
import CONTACT from './img/contact.png';
import DASHBOARD_POOL from './img/dashboard-pool.png';
import DASHBOARD_REQUEST from './img/dashboard-request.png';
import DASHBOARD_SEARCH from './img/dashboard-search.png';
import DOCTARI_AVATAR from './img/doctari-avatar.png';
import DOCTARI_LOGO from './img/doctari-logo.png';
import LOGO from './img/doctari-pro-logo.png';
import DOCTORS_STATUSES from './img/doctors-statuses.png';
import DOCUMENT_ICON from './img/document-icon.png';
import EMPTY_INBOX from './img/empty-inbox.png';
import EMPTY_STATE from './img/empty-state.png';
import EXTERNAL_LINK_ICON from './img/external-link-icon.png';
import FETCHING_PRICES from './img/fetching-prices.png';
import FIRST_BOOKING from './img/first-booking.png';
import LOGIN_BACKGROUND from './img/login-background.jpg';
import NO_IMAGE from './img/no-image.png';
import PEOPLE_NETWORK from './img/people-network.png';
import SAD_FACE from './img/sad-face.png';
import ACCOUNT_BLOCK from './svgs/account-block.svg';
import ACCOUNT_CANCEL from './svgs/account-cancel.svg';
import ACCOUNT_CHECK from './svgs/account-check.svg';
import ACCOUNT_GROUP from './svgs/account-group.svg';
import ACCOUNT_UNBLOCK from './svgs/account-unblock.svg';
import BELL from './svgs/bell.svg';
import BRIEFCASE_VARIANT from './svgs/briefcase-variant.svg';
import CALENDAR from './svgs/calendar.svg';
import CHECK from './svgs/check.svg';
import CHEVRON_RIGHT from './svgs/chevron-right.svg';
import CLOCK_NINE_OUTLINE from './svgs/clock-time-nine-outline.svg';
import CLOSE from './svgs/close.svg';
import COMPUTER_WOMAN from './svgs/computer-woman.svg';
import DELETE from './svgs/delete.svg';
import DOCTARI_ICON from './svgs/doctari-icon.svg';
import DOCTOR_SUITCASE from './svgs/doctor-suitcase.svg';
import DOCTOR from './svgs/doctor.svg';
import DUPLICATE from './svgs/duplicate.svg';
import EDIT from './svgs/edit.svg';
import EMAIL from './svgs/email.svg';
import FAVORITE from './svgs/favorite.svg';
import FORWARDED_ACCOUNT from './svgs/forwarded-account.svg';
import HOME from './svgs/home.svg';
import LOADING from './svgs/loading.svg';
import LOGO_ICON from './svgs/logo-icon.svg';
import PDF_BOX from './svgs/pdf-box.svg';
import PERSON_EDIT from './svgs/person-edit.svg';
import PIN from './svgs/pin.svg';
import PLANERIO_LOGO from './svgs/planerio-logo.svg';
import POOL_ADD from './svgs/pool-add.svg';
import POOL_REMOVE from './svgs/pool-remove.svg';
import RECOMMEND from './svgs/recommend.svg';
import STAR from './svgs/star.svg';
import FAVORITE_REMOVE from './svgs/star_border.svg';
import TRASH_CAN from './svgs/trash-can.svg';
import UPLOAD_CV from './svgs/upload-cv.svg';
import VERIFIED_USER from './svgs/verified-user.svg';
import WATCHLIST from './svgs/watchlist.svg';

export const ANIMATIONS = {
  APPROVED_CHECKED,
  OPENING_ENVELOPE,
};

export const SVGS = {
  ACCOUNT_BLOCK,
  ACCOUNT_CANCEL,
  ACCOUNT_CHECK,
  ACCOUNT_GROUP,
  ACCOUNT_UNBLOCK,
  BELL,
  BRIEFCASE_VARIANT,
  CALENDAR,
  CHECK,
  CHEVRON_RIGHT,
  CLOCK_NINE_OUTLINE,
  CLOSE,
  COMPUTER_WOMAN,
  DELETE,
  DOCTARI_ICON,
  DOCTOR,
  DOCTOR_SUITCASE,
  DUPLICATE,
  EDIT,
  EMAIL,
  FAVORITE,
  FAVORITE_REMOVE,
  FORWARDED_ACCOUNT,
  HOME,
  LOADING,
  LOGO_ICON,
  PDF_BOX,
  PERSON_EDIT,
  PIN,
  PLANERIO_LOGO,
  POOL_ADD,
  POOL_REMOVE,
  RECOMMEND,
  STAR,
  TRASH_CAN,
  VERIFIED_USER,
  UPLOAD_CV,
  WATCHLIST,
};

export const IMAGES = {
  CONTACT,
  COMPUTER_WOMAN,
  DASHBOARD_REQUEST,
  DASHBOARD_POOL,
  DASHBOARD_SEARCH,
  DOCTORS_STATUSES,
  DOCUMENT_ICON,
  EMPTY_INBOX,
  EMPTY_STATE,
  EXTERNAL_LINK_ICON,
  FIRST_BOOKING,
  LOGIN_BACKGROUND,
  LOGO,
  DOCTARI_AVATAR,
  DOCTARI_LOGO,
  NO_IMAGE,
  SAD_FACE,
  FETCHING_PRICES,
  PEOPLE_NETWORK,
};

export const BANNERS = {
  womensday1,
};
