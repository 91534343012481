import { useContext, useEffect, useState } from 'react';

import { ErrorUtilContext } from '@@frontend/ReactContext';
import ErrorSnackbar from '@@frontend/components/Snackbar/ErrorSnackbar';

import type { ReactElement } from 'react';

export const ErrorElement = (): ReactElement => {
  const errorUtilContext = useContext(ErrorUtilContext);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    errorUtilContext.setupErrorHandler(setHasError);
  }, [errorUtilContext]);

  return (
    <ErrorSnackbar
      isVisible={ hasError }
    />
  );
};
