/* eslint-disable max-classes-per-file */
import type { DateTime as LuxonDateTime } from 'luxon';

export function messageFromInvalidDateTime(dateTime: LuxonDateTime, fallback: string): string {
  const { invalidExplanation, invalidReason } = dateTime;
  const message = invalidExplanation ? `${invalidReason}: ${invalidExplanation}` : invalidReason;

  return message ?? fallback;
}

export class LocalDateError extends Error {}

export class InvalidLocalDateError extends LocalDateError {
  constructor(reason: string) {
    super(`Invalid LocalDate: ${reason}`);
  }
}

export class InvalidDateTimeError extends Error {
  constructor(dateTime: LuxonDateTime) {
    super(messageFromInvalidDateTime(dateTime, 'Invalid DateTime'));
  }
}
