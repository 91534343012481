class ErrorUtil {
  // eslint-disable-next-line class-methods-use-this
  setError: (error: boolean) => void = (error: boolean) => { console.log('State update callback needs to be setup here!'); };

  message = '';

  setupErrorHandler(setErrorCallback: (error: boolean) => void): void {
    this.setError = setErrorCallback;
  }

  showError(message: string): void {
    this.setError(false);
    this.message = message;
    this.setError(true);
  }

  removeError(): void {
    this.message = '';
    this.setError(false);
  }
}

export default ErrorUtil;
