import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import { DEFAULT_LANGUAGE, FALLBACK_LANGUAGE } from '@@common-legacy/constants/i18n';
import i18nShared from '@@common-legacy/i18n';

import de_cookie_overlay from './locales/de/cookie_overlay.json';
import de_frontend from './locales/de/frontend.json';

import type { Namespace, WithT } from 'i18next';

const defaultNS = 'frontend';
const resources = {
  de: {
    [defaultNS]: de_frontend,
    cookie_overlay: de_cookie_overlay,
  },
} as const;
export type I18nextDefaultNS = typeof defaultNS;
export type I18nextResources = typeof resources[typeof DEFAULT_LANGUAGE];

i18next
  .use(initReactI18next)
  .init({
    supportedLngs: [DEFAULT_LANGUAGE],
    nonExplicitSupportedLngs: true,
    resources,
    lng: DEFAULT_LANGUAGE,
    fallbackLng: FALLBACK_LANGUAGE,
    debug: false,
    ns: [defaultNS],
    defaultNS,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
      format: i18nShared.i18nextFormatter,
    },
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    },
  })
  .then(() => {
    i18nShared.setCurrentLanguage(i18next.language);
    i18next.on('languageChanged', (lng) => {
      i18nShared.setCurrentLanguage(lng);
    });
  });

export { DEFAULT_LANGUAGE, FALLBACK_LANGUAGE };
// allow implicit default namespace and explicit namespaces at the same time, see https://github.com/i18next/i18next/issues/1855
export default i18next as typeof i18next & WithT<Namespace | I18nextDefaultNS>;
