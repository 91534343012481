import type { LocalDate } from '@@util/date/LocalDate';

export interface DateRange {
  startDate: LocalDate,
  endDate: LocalDate,
}

export interface DateRangeFormatOptions {
  separator?: string,
  collapseSame?: 'month' | 'year' | false,
  showRangeOnSameDay?: boolean,
  longFormat?: boolean,
}

export function formatDateRange(dateRange: DateRange, options?: DateRangeFormatOptions): string {
  const {
    separator = ' – ',
    collapseSame = 'month',
    showRangeOnSameDay = false,
    longFormat = false,
  } = options ?? {};

  // TODO: support other locales? (including "matching" formats)
  const startDate = dateRange.startDate.setLocale('de');
  const endDate = dateRange.endDate.setLocale('de');
  const formats = longFormat ? {
    full: 'ccc. dd.MM.yyyy',
    sameYear: 'ccc. dd.MM.',
    sameMonth: 'ccc. dd.MM.',
  } : {
    full: 'dd.MM.yyyy',
    sameYear: 'dd.MM.',
    sameMonth: 'dd.',
  };

  const end = endDate.toFormat(formats.full);

  if (!showRangeOnSameDay && startDate.hasSame(endDate, 'day')) {
    return end;
  }

  let startFormat: string | undefined;
  if (startDate.hasSame(endDate, 'year') && collapseSame !== false) {
    if (startDate.hasSame(endDate, 'month') && collapseSame !== 'year') {
      startFormat = formats.sameMonth;
    } else {
      startFormat = formats.sameYear;
    }
  } else {
    startFormat = formats.full;
  }

  const start = startDate.toFormat(startFormat);

  return `${start}${separator}${end}`;
}
