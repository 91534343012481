import MaterialDialog from '@mui/material/Dialog';
import Grow from '@mui/material/Grow';
import React from 'react';

import type { DialogProps } from '@mui/material/Dialog';

const Dialog = ({ children, ...rest }: DialogProps): JSX.Element => (
  <MaterialDialog
    { ...rest }
    TransitionComponent={ Grow }
  >
    { children }
  </MaterialDialog>
);

export default Dialog;
