import { combineReducers } from 'redux';

import { ROOT_ACTIONS } from '@@frontend/constants/actions';

import healthcareInstitutionDepartmentReducer from './healthcareInstitutionDepartment';
import notificationsReducer from './notifications';
import requestReducer from './request';

import type { AnyAction, Reducer } from 'redux';

export const rootReducer: Reducer<AppState> = (state: AppState | undefined, action: AnyAction): AppState => {
  return action.type === ROOT_ACTIONS.CLEAR_STATE ? appReducer(undefined, action) : appReducer(state, action);
};

// TODO -> see if we can remove request reducer
const appReducer = combineReducers({
  healthcareInstitutionDepartment: healthcareInstitutionDepartmentReducer,
  notifications: notificationsReducer,
  request: requestReducer,
});

export type AppState = ReturnType<typeof appReducer>;
