const NOTIFICATIONS_ACTIONS = {
  SET_NOTIFICATIONS_DATA: 'SET_NOTIFICATIONS_DATA',
};

const HEALTHCARE_INSTITUTION_DEPARTMENT_ACTIONS = {
  SET_PRESELECTED_HEALTHCARE_INSTITUTION_DEPARTMENT_DATA: 'SET_PRESELECTED_HEALTHCARE_INSTITUTION_DEPARTMENT_DATA',
  CLEAR_PRESELECTED_HEALTHCARE_INSTITUTION_DEPARTMENT_DATA: 'CLEAR_PRESELECTED_HEALTHCARE_INSTITUTION_DEPARTMENT_DATA',
};

const REQUEST_ACTIONS = {
  SET_PRESELECTED_REQUEST_DATA: 'SET_PRESELECTED_REQUEST_DATA',
  CLEAR_PRESELECTED_REQUEST_DATA: 'CLEAR_PRESELECTED_REQUEST_DATA',
};

const ROOT_ACTIONS = {
  CLEAR_STATE: 'CLEAR_STATE',
};

export { NOTIFICATIONS_ACTIONS, HEALTHCARE_INSTITUTION_DEPARTMENT_ACTIONS, REQUEST_ACTIONS, ROOT_ACTIONS };
