import { REQUEST_ACTIONS } from '@@frontend/constants/actions';

import type { PartialRequest } from '@@common-legacy/requests/common';
import type { Reducer } from 'redux';

type RequestStateType = {
  preselectedRequestData: PartialRequest;
}

const initialState: RequestStateType = {
  preselectedRequestData: {},
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const requestStateTypeReducer: Reducer<RequestStateType> = (state: RequestStateType = initialState, action): RequestStateType => {
  switch (action.type) {
    case REQUEST_ACTIONS.SET_PRESELECTED_REQUEST_DATA: {
      return {
        ...state,
        preselectedRequestData: action.data,
      };
    }
    case REQUEST_ACTIONS.CLEAR_PRESELECTED_REQUEST_DATA: {
      return {
        ...state,
        preselectedRequestData: {},
      };
    }
    default:
      return state;
  }
};

export default requestStateTypeReducer;
