import { createRoutes } from '@@common-legacy/routes';

const baseUrl = process.env.REACT_APP_WEBSITE_URL;
if (!baseUrl) {
  throw new Error('Missing REACT_APP_WEBSITE_URL');
}

const routes = createRoutes({
  defaultAbsolute: false,
  baseUrl,
});

export default routes;
