import numeral from 'numeral';

import { DEFAULT_LANGUAGE, FALLBACK_LANGUAGE } from '@@common-legacy/constants/i18n';
import { LocalDate } from '@@util/date/LocalDate';

import type { InterpolationOptions } from 'i18next';

type FormatFunction = InterpolationOptions['format'];

let currentLanguage: string = DEFAULT_LANGUAGE;

const DECIMAL_DELIMITERS: Record<string, string> = {
  de: ',',
  en: '.',
};

const THOUSANDS_DELIMITERS: Record<string, string> = {
  de: '.',
  en: ',',
};

if (numeral.locales.de === undefined) {
  numeral.register('locale', 'de', {
    delimiters: {
      decimal: DECIMAL_DELIMITERS.de,
      thousands: THOUSANDS_DELIMITERS.de,
    },
    abbreviations: {
      thousand: 'Tsd.',
      million: 'Mio.',
      billion: 'Mrd.',
      trillion: 'Bio.',
    },
    ordinal() {
      return '.';
    },
    currency: {
      symbol: '€',
    },
  });
}

if (numeral.locales.en === undefined) {
  numeral.register('locale', 'en', {
    delimiters: {
      decimal: DECIMAL_DELIMITERS.en,
      thousands: THOUSANDS_DELIMITERS.en,
    },
    abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't',
    },
    ordinal(n: number) {
      const s = ['th', 'st', 'nd', 'rd'];
      const v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    },
    currency: {
      symbol: '$',
    },
  });
}

// eslint-disable-next-line
const i18nextFormatter: FormatFunction = (value: any, format?: string, lng?: string): string => {
  if (LocalDate.isLocalDate(value)) {
    return formatLocalDate(value, format, lng);
  }

  if (format === 'price') format = 'price:0.00';
  if (format === 'number') format = 'number:0.[00]';
  if (format === 'percent') format = 'percent:0.[00]';

  switch (format) {
    case 'price:0.00': return formatPrice(value, '0,0.00', lng);
    case 'price:0.[00]': return formatPrice(value, '0,0.[00]', lng);
    case 'number:0.00': return formatNumber(value, '0,0.00', lng);
    case 'number:0.[00]': return formatNumber(value, '0,0.[00]', lng);
    case 'percent:0.00': return formatNumber(value, '0,0.00%', lng);
    case 'percent:0.[00]': return formatNumber(value, '0,0.[00]%', lng);
    default: return value;
  }
};

const formatLocalDate = (value: LocalDate, format?: string, lng?: string): string => {
  if (format) {
    return value.toFormat(format);
  }

  const lang = lng ?? currentLanguage ?? DEFAULT_LANGUAGE;

  switch (lang) {
    case 'de': return `${value.toFormat('dd.MM.yyyy')}`;
    case 'en': return `${value.toFormat('dd/MM/yyyy')}`;
    default: return `${value.toFormat('dd.MM.yyyy')}`;
  }
};

// eslint-disable-next-line
const formatPrice = (value: any, format: string, lng?: string): string => {
  return formatNumber(value, `${format} $`, lng);
};

// eslint-disable-next-line
const formatNumber = (value: any, format: string, lng?: string): string => {
  let num;
  if (value === null || value === undefined) {
    num = NaN;
  } else if (typeof value === 'number') {
    num = value;
  } else {
    num = parseFloat(value);
  }

  if (Number.isNaN(num)) {
    return 'NaN';
  }

  numeral.locale(lng ?? currentLanguage ?? DEFAULT_LANGUAGE);
  return numeral(num).format(format).replace(' ', '\xa0');
};

const getDecimalDelimiter = (locale?: string): string => DECIMAL_DELIMITERS[locale ?? '']
  ?? DECIMAL_DELIMITERS[currentLanguage]
  ?? DECIMAL_DELIMITERS[DEFAULT_LANGUAGE]
  ?? DECIMAL_DELIMITERS[FALLBACK_LANGUAGE]
  ?? DECIMAL_DELIMITERS.de;

const getThousandsDelimiter = (locale?: string): string => THOUSANDS_DELIMITERS[locale ?? '']
  ?? THOUSANDS_DELIMITERS[currentLanguage]
  ?? THOUSANDS_DELIMITERS[DEFAULT_LANGUAGE]
  ?? THOUSANDS_DELIMITERS[FALLBACK_LANGUAGE]
  ?? THOUSANDS_DELIMITERS.de;

const setCurrentLanguage = (lng: string): void => {
  currentLanguage = lng ?? DEFAULT_LANGUAGE;
};

export default {
  i18nextFormatter,
  formatLocalDate,
  formatPrice,
  formatNumber,
  getDecimalDelimiter,
  getThousandsDelimiter,
  setCurrentLanguage,
};
