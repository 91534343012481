import { useContext } from 'react';

import { AuthContext } from '@@frontend/context/AuthContextProvider';

import type { AuthContextProps } from '@@frontend/context/AuthContextProvider';

const useAuthContext = (): AuthContextProps => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuthContext must be used within an AuthContextProvider');
  }

  return context;
};

export default useAuthContext;
