export default {
  // BASE CONFIG
  API_URL: process.env.REACT_APP_API_URL as string,
  WEBSITE_URL: process.env.REACT_APP_WEBSITE_URL as string,

  // AXIOS CONFIG
  HTTP_CLIENT_TIMEOUT: 20000,

  // OTHER CONFIG
  MESSENGER_UI_ENABLED: true,
  DVELOP: process.env.REACT_APP_DVELOP,

  getAppVersion: (): string | undefined => process.env.REACT_APP_VERSION,
} as const;
