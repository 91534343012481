import CircularProgress from '@mui/material/CircularProgress';

import type { FC } from 'react';

export const GlobalLoader: FC = () => {
  return (
    <div className='flex justify-center items-center min-h-full min-w-full'>
      <CircularProgress
        size={ 68 }
        color='primary'
      />
    </div>
  );
};
