/* eslint-disable no-nested-ternary */

export type Comparator<T> = (a: T, b: T) => number;

export const naturalOrder = <T>(a: T, b: T): number => (a > b ? 1 : b > a ? -1 : 0);

export const localeNaturalOrder = <T extends { localeCompare: (that: T) => number }>(a: T, b: T): number => a.localeCompare(b);

export const compareAll = <T>(...compareFns: Comparator<T>[]): Comparator<T> => (a, b) => {
  for (const compareFn of compareFns) {
    const result = compareFn(a, b);
    if (result !== 0) return result;
  }
  return 0;
};

export const reversed = <T>(compareFn: Comparator<T>): Comparator<T> => (a, b) => compareFn(b, a);
export const reverseNaturalOrder = reversed(naturalOrder);

export const sortUndefinedLast = <T>(compareFn: Comparator<T> = naturalOrder): Comparator<T | undefined> => (a, b): number => {
  const aDefined = a !== undefined;
  const bDefined = b !== undefined;

  if (aDefined && bDefined) {
    return compareFn(a, b);
  }

  return !bDefined ? aDefined ? -1 : 0 : 1;
};

export function sortBy<T, K>(keyExtractor: (v: T) => K, compareFn?: Comparator<K>): Comparator<T>;
export function sortBy<T, K extends keyof T>(key: K, compareFn?: Comparator<T[K]>): Comparator<T>;
export function sortBy<T, K extends keyof T, R extends T[K]>(key: keyof T | ((v: T) => R), compareFn: Comparator<R> = naturalOrder): Comparator<T> {
  if (typeof key === 'function') {
    return (a: T, b: T) => compareFn(key(a), key(b));
  }
  return (a: T, b: T) => compareFn(a[key] as R, b[key] as R);
}

export function sortByGivenOrder<T>(order: readonly T[]): Comparator<T> {
  const sortLast = order.length;
  return sortBy((v) => {
    const index = order.indexOf(v);
    return index === -1 ? sortLast : index;
  });
}
