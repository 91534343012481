import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { TssCacheProvider } from 'tss-react';

import { theme } from './index';

import type { FC, PropsWithChildren } from 'react';

const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

const tssCache = createCache({
  key: 'tss',
});

// there is a copy of this in @@components/theme/
const StyleProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <CacheProvider value={ muiCache }>
      <TssCacheProvider value={ tssCache }>
        <ThemeProvider theme={ theme }>
          <CssBaseline />
          { children }
        </ThemeProvider>
      </TssCacheProvider>
    </CacheProvider>
  );
};

export default StyleProvider;
