import { CANDIDATE_ROLES, CLINIC_ROLES, DOCTARI_ROLES, USER_ROLES } from './AccessControl';

import type { CandidateRole, ClinicRole, DoctariRole, Role, UserRole } from './AccessControl';

export function isUserRole(role: Role): role is UserRole {
  return USER_ROLES.includes(role as UserRole);
}

export function isClinicUserRole(role: Role): role is ClinicRole {
  return CLINIC_ROLES.includes(role as ClinicRole);
}

export function isDoctariUserRole(role: Role): role is DoctariRole {
  return DOCTARI_ROLES.includes(role as DoctariRole);
}

export function isCandidateRole(role: Role): role is CandidateRole {
  return CANDIDATE_ROLES.includes(role as CandidateRole);
}
