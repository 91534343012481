import { hotjar } from 'react-hotjar';

import { DEFAULT_COOKIE_ACCEPTED_EVENT } from '@@frontend/constants/cookies';
import { acceptedCookies, getCookieValue } from '@@frontend/utils/cookies';

const _initHotjar = (): void => {
  if (process.env.REACT_APP_ENVIRONMENT_NAME === 'production') {
    hotjar.initialize(2086384, 6);
  }

  window.removeEventListener(DEFAULT_COOKIE_ACCEPTED_EVENT, _initHotjar);
};

if (acceptedCookies()) {
  _initHotjar();
} else if (!getCookieValue()) {
  window.addEventListener(DEFAULT_COOKIE_ACCEPTED_EVENT, _initHotjar, false);
}
